

































































import Vue from "vue";
import Component from "vue-class-component";
import { Getter, namespace, State } from "vuex-class";
import { AuthUser } from "@/smartmsi";
import Drawer from "@/layouts/components/Drawer.vue";

const app = namespace("app");
@Component({
  components: { Drawer },
})
export default class Toolbar extends Vue {
  notifications = [
    "Mike, Thanos is coming",
    "5 new avengers joined the team",
    "You're now friends with Capt",
    "Another Notification",
    "Another One - Dj Khalid voice",
  ];
  responsive = false;
  title = "Bienvenue";

  isDrawerOpen = true;
  breakPoint = 1400;

  @Getter authorized!: boolean;
  @app.State dark!: boolean;

  @State user!: AuthUser;
  @State impersonator!: AuthUser;

  get color() {
    if (this.impersonator) {
      return "purple";
    }
    return "";
  }

  @app.Mutation setDrawer!: Function;
  @app.Mutation setDark!: Function;

  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  }

  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  }

  switchDarkMode() {
    this.setDark(!this.dark);
  }

  toggleDrawer() {
    this.setDrawer(!this.$store.state.app.drawer);
  }

  logout() {
    this.$store.dispatch("logout");
  }

  changeLocale(locale: string) {
    this.$store.dispatch("changeLocale", locale);
  }

  onResponsiveInverted() {
    this.responsive = window.innerWidth < this.breakPoint;
  }

  get languages() {
    return this.$store.state.availableLanguages;
  }
}
