





























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace, State } from "vuex-class";
import VueI18n from "vue-i18n";
import { AuthUser } from "@/smartmsi";

const app = namespace("app");

interface DrawerMenuItem {
  to?: string;
  icon: string;
  text: string | VueI18n.TranslateResult;
  subLinks?: DrawerMenuItem[];
}

@Component
export default class Drawer extends Vue {
  @State user!: AuthUser;
  @app.State color!: string;

  @Prop() isDrawerOpen!: boolean;
  @Prop() breakPoint!: number;

  get logo() {
    return this.$vuetify.theme.dark
      ? require("@/assets/logo-dark.png")
      : require("@/assets/logo-light.png");
  }

  get links() {
    const links: DrawerMenuItem[] = [
      {
        to: "/",
        icon: "mdi-view-dashboard",
        text: this.$t("dashboard.title"),
      },
      {
        to: "/company",
        icon: "fa fa-landmark",
        text: this.$t("models.company.selves"),
      },
      {
        to: "/portfolio",
        icon: "fa fa-sitemap",
        text: this.$t("portfolio.title"),
      },
      {
        to: "/variations",
        icon: "fa fa-cogs",
        text: this.$t("config.title"),
      },

      {
        to: "/stock",
        icon: "fa fa-chart-line",
        text: this.$t("stock.title"),
      },
    ];

    if (this.user.role === "admin") {
      links.push({
        icon: "fa-wrench",
        text: "Admin",
        subLinks: [
          {
            to: "/rbac",
            icon: "fa fa-cogs",
            text: this.$t("rbac.title"),
          },
          {
            to: "/region",
            icon: "fa fa-globe-africa",
            text: this.$t("models.region.selves"),
          },
          {
            to: "/country",
            icon: "fa fa-flag",
            text: this.$t("models.country.selves"),
          },
          {
            to: "/fund",
            icon: "fa fa-money-bill",
            text: this.$t("models.fund.selves"),
          },
          {
            to: "/user",
            icon: "fa fa-users",
            text: this.$t("models.user.selves"),
          },
          {
            to: "/stock-market",
            icon: "fa fa-folder-open",
            text: this.$t("models.stock_market.selves"),
          },
          {
            to: "/certification-authority",
            icon: "fa fa-folder-open",
            text: this.$t("models.certification_authority.selves"),
          },
          {
            to: "/sector",
            icon: "fa fa-folder-open",
            text: this.$t("models.sector.selves"),
          },
          {
            to: "/activity",
            icon: "fa fa-clipboard-list",
            text: this.$t("models.activity.selves"),
          },
        ],
      });
    }

    return links;
  }
}
